import { AxiosRequestConfig } from 'axios';

import { BaseAPI } from '@shared/api/base-api';
import { Page } from '@shared/api/dto/Page';
import { SortDirections } from '@shared/constants/SortDirections';
import { UserSortingFields } from '@shared/constants/sortingFields';
import { UserRoleTypes } from '@shared/constants/UserRoleTypes';
import { parseUserRoles } from '@shared/util/users-helper';

/**
 * Command to create a user
 * @export
 * @interface ICreateUserDto
 */
export interface ICreateUserDto {
  /**
   * Last name
   * @type {string}
   * @memberof ICreateUserDto
   */
  lastName: string;
  /**
   * First Name
   * @type {string}
   * @memberof ICreateUserDto
   */
  firstName: string;
  /**
   * Email address
   * @type {string}
   * @memberof ICreateUserDto
   */
  email: string;
  /**
   * The user roles
   * @type {number}
   * @memberof ICreateUserDto
   */
  userRoles: UserRoleTypes;
  /**
   * The phone number
   * @type {string}
   * @memberof ICreateUserDto
   */
  phone: string;
  /**
   * Contact information
   * @type {string}
   * @memberof ICreateUserDto
   */
  contactInformation: string;
  /**
   * User tags
   * @type {string}
   * @memberof ICreateUserDto
   */
  userTag: string;
  /**
   * Specifies, if this user needs to authenticate using two factor
   * authentication
   * @type {boolean}
   * @memberof ICreateUserDto
   */
  twoFactorEnabled?: boolean;
}

/**
 * Updates user DTO. It contains fields that are updatable within users account,
 * and fields that others users can update.
 * @export
 * @interface IUpdateUserDto
 */
export interface IUpdateUserDto {
  /**
   * The first name
   * @type {string}
   * @memberof IUpdateUserDto
   */
  firstName: string;
  /**
   * The last name
   * @type {string}
   * @memberof IUpdateUserDto
   */
  lastName: string;
  /**
   * The user roles to be applied
   * @type {number}
   * @memberof IUpdateUserDto
   */
  userRoles: UserRoleTypes;
  /**
   * The phone number
   * @type {string}
   * @memberof IUpdateUserDto
   */
  phone: string;
  /**
   * The email
   * @type {string}
   * @memberof IUpdateUserDto
   */
  email: string;
  /**
   * The contact information
   * @type {string}
   * @memberof IUpdateUserDto
   */
  contactInformation: string;
  /**
   * The user tag
   * @type {string}
   * @memberof IUpdateUserDto
   */
  userTag: string;
  /**
   * Specifies, if this user needs to authenticate using two factor
   * authentication.
   * @type {boolean}
   * @memberof IUpdateUserDto
   */
  twoFactorEnabled: boolean;
}

/*
 * Update external user DTO. It contains fields that are updateable within
 * users that are provided by external identity provider.
 */
export interface IUpdateExternalUserDto {
  /**
   * The user roles to be applied
   */
  userRoles: UserRoleTypes;
  /**
   * The user tag
   */
  userTag: string;
}

/**
 * Model to describe details about a user account
 * @export
 * @interface IUserDto
 */
export interface IUserDto {
  /**
   * The user id
   * @type {number}
   * @memberof IUserDto
   */
  id: string;
  /**
   * The first name
   * @type {string}
   * @memberof IUserDto
   */
  firstName: string;
  /**
   * The last name
   * @type {string}
   * @memberof IUserDto
   */
  lastName: string;
  /**
   * The phone number
   * @type {string}
   * @memberof IUserDto
   */
  phone: string;
  /**
   * The email
   * @type {string}
   * @memberof IUserDto
   */
  email: string;
  /**
   * Specifies, if the given email has been confirmed
   * @type {boolean}
   * @memberof IUserDto
   */
  emailConfirmed: boolean;
  /**
   * Specifies, if the phone number has already been confirmed
   * @type {boolean}
   * @memberof IUserDto
   */
  phoneNumberConfirmed: boolean;
  /**
   * Contact information
   * @type {string}
   * @memberof IUserDto
   */
  contactInformation: string;
  /**
   * Tenant of this user
   * @type {string}
   * @memberof IUserDto
   */
  tenant: string;
  /**
   * User roles
   * @type {number}
   * @memberof IUserDto
   */
  userRoles: UserRoleTypes;
  /**
   * Specifies the time period, since the user has logged in the last time.
   * @type {number}
   * @memberof IUserDto
   */
  secondsSinceLastSeen: number | null;
  /**
   * The user tag
   * @type {string}
   * @memberof IUserDto
   */
  userTag: string;
  /**
   * Specifies, if the user needs to authenticate using two factor
   * authentication.
   * @type {boolean}
   * @memberof IUserDto
   */
  twoFactorEnabled: boolean;
}

export interface IUserInChargeDto {
  /**
   * The user id
   * @type {string}
   * @memberof IUserInChargeDto
   */
  id: string;

  /**
   * The first name
   * @type {string}
   * @memberof IUserInChargeDto
   */
  firstName: string;

  /**
   * The last name
   * @type {string}
   * @memberof IUserInChargeDto
   */
  lastName: string;

  /**
   * The email
   * @type {string}
   * @memberof IUserInChargeDto
   */
  email: string;

  /**
   * User roles
   * @type {number}
   * @memberof IUserInChargeDto
   */
  userRoles: UserRoleTypes;
}

/**
 * A data to create an organisation manager.
 * @export
 * @interface ICreateOrganisationManagerDto
 */
export interface ICreateOrganisationManagerDto extends ICreateUserDto {
  /**
   * The tenant for which the organisation manager should be created.
   * @memberof ICreateOrganisationManagerDto
   */
  tenant: string;
}

/**
 * Dto to change the phone number of a organisation manager.
 * @export
 * @interface IChangeOrgManagerPhoneNumberDto
 */
export interface IChangeOrgManagerPhoneNumberDto {
  /**
   * The email of the user, for which the phone number should be set.
   * @memberof ICreateOrganisationManagerDto
   */
  email: string;
  /**
   * The new telephone number.
   * @memberof IChangeOrgManagerPhoneNumberDto
   */
  phone: string;
  /**
   * The tenant of the user.
   * @memberof IChangeOrgManagerPhoneNumberDto
   */
  tenant: string;
}

export interface DisableOrgManager2FaDto {
  tenant: string;
  email: string;
}

type IServerObjectWithUserRoles = { userRoles: string };
type IResultObjectWithUserRoles = { userRoles: UserRoleTypes };
type IServerUserDto = Omit<IUserDto, 'userRoles'> & { userRoles: string };
type IServerUserInChargeDto = Omit<IUserInChargeDto, 'userRoles'> & { userRoles: string };

const adjustObjectWithUserRoles = <T extends IServerObjectWithUserRoles>(
  item: T,
): Omit<T, 'userRoles'> & IResultObjectWithUserRoles => {
  return {
    ...item,
    userRoles: parseUserRoles(item.userRoles),
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = (baseApi: BaseAPI) => {
  return {
    /**
     * Deletes a user.
     * @param {string} id ID of the user to delete
     */
    async delete(id: string): Promise<void> {
      await baseApi.delete(`/api/users/${id}`);
    },

    /**
     * Get user information by the user ID.
     * @param {string} id ID of the user to load.
     * @returns a DTO containing the information about the user with the
     *     given user ID.
     */
    async getUserById(id: string): Promise<IUserDto> {
      const response = await baseApi.get<IServerUserDto>(`/api/users/${id}`);
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Gets the user profile for the current logged in user.
     * @returns a DTO containing the information about the current user.
     */
    async getMyself(): Promise<IUserDto> {
      const response = await baseApi.get<IServerUserDto>('/api/users/current');
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Gets a page of users.
     * @param {string} [search] the search query;
     * @param {SortDirections} [direction] the sort direction;
     * @param {UserSortingFields} [field] the field to be used for sorting;
     * @param {number} [page] the page number to retrieve;
     * @param {number} [count] the amount of users on a page.
     * @returns the page of users.
     */
    async getPaged(
      search: string,
      direction: SortDirections,
      field: UserSortingFields,
      page?: number,
      count?: number,
    ): Promise<Page<IUserDto>> {
      const localVarQueryParameter: { [P in any]: P } = {};
      if (page !== undefined) {
        (localVarQueryParameter as any).page = page;
      }

      if (count !== undefined) {
        (localVarQueryParameter as any).count = count;
      }

      if (search !== undefined) {
        (localVarQueryParameter as any).search = search;
      }

      if (direction !== undefined) {
        (localVarQueryParameter as any).direction = direction;
      }

      if (field !== undefined) {
        (localVarQueryParameter as any).field = field;
      }

      const response = await baseApi.get<Page<IServerUserDto>>('/api/users', localVarQueryParameter);

      const { items, ...responseData } = response.data;
      return {
        ...responseData,
        items: items.map((x) => adjustObjectWithUserRoles(x)),
      };
    },

    /**
     * Gets the users in charge for an organisation.
     * @returns the users in charge.
     */
    async getUsersInCharge(options?: AxiosRequestConfig): Promise<IUserInChargeDto[]> {
      const response = await baseApi.get<IServerUserInChargeDto[]>('/api/users/userInCharge', undefined, options);
      return response.data.map((x) => adjustObjectWithUserRoles(x));
    },

    /**
     * Creates a user.
     * @param {ICreateUserDto} [dto] a DTO containing the
     * information about the user to create.
     * @returns a DTO with the info of the created user.
     */
    async createUser(dto: ICreateUserDto): Promise<IUserDto> {
      const response = await baseApi.post<IServerUserDto>('/api/users', undefined, dto);
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Update user info.
     * @param {string} userId the ID of the user to be updated;
     * @param {IUpdateUserDto} [updateUserDto] the properties to be applied to the user.
     * @returns the updated user.
     */
    async updateUserDetails(userId: string, updateUserDto: IUpdateUserDto): Promise<IUserDto> {
      const response = await baseApi.put<IServerUserDto>(`/api/users/${userId}`, undefined, updateUserDto);
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Update external user info.
     * @param {string} userId the ID of the user to be updated;
     * @param {IUpdateExternalUserDto} [updateUserDto] the properties to be applied to the user.
     * @returns the updated user.
     */
    async updateExternalUserDetails(userId: string, updateUserDto: IUpdateExternalUserDto): Promise<IUserDto> {
      const response = await baseApi.put<IServerUserDto>(`/api/users/external/${userId}`, undefined, updateUserDto);
      return adjustObjectWithUserRoles(response.data);
    },
  };
};

/**
 * Organisation manager api interface.
 * @export
 */
export const OrganisationManagerApiFactory = (baseApi: BaseAPI) => {
  return {
    /**
     * Creates an organisation manager for the given tenant.
     * @param {ICreateOrganisationManagerDto} dto a DTO containing the information about
     * the user to create.
     * @returns the created user.
     */
    async create(dto?: ICreateOrganisationManagerDto): Promise<IUserDto> {
      const response = await baseApi.post<IServerUserDto>('/api/organisationManagers', undefined, dto);
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Changes the phone number of an organisation manager.
     * @param {ICreateOrganisationManagerDto} dto a DTO with info for number changing.
     * @returns the changed user.
     */
    async changePhoneNumber(dto?: IChangeOrgManagerPhoneNumberDto): Promise<IUserDto> {
      const response = await baseApi.put<IServerUserDto>('/api/organisationManagers/phonenumber', undefined, dto);
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Disable two factor authorization for the given organisation admin
     */
    async disableTwoFactor(dto: DisableOrgManager2FaDto): Promise<IUserDto> {
      const response = await baseApi.put<IServerUserDto>('/api/organisationManagers/disable-2fa', undefined, dto);
      return adjustObjectWithUserRoles(response.data);
    },

    /**
     * Gets organisation managers for requesting tenant.
     * @returns Organisation managers.
     */
    async getOrganisationManagers(
      tenant: string,
      sortField: UserSortingFields,
      sortDirection: SortDirections,
      options?: AxiosRequestConfig,
    ): Promise<IUserDto[]> {
      const response = await baseApi.get<IServerUserDto[]>(
        '/api/organisationManagers',
        { tenant, sortField, sortDirection },
        { ...options },
      );
      return response.data.map((x) => adjustObjectWithUserRoles(x));
    },
  };
};
