import { combineReducers, Action } from 'redux';

import * as UserActionTypes from '@shared/redux/actions/users-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import {
  CreateUserModalState,
  CurrentUserState,
  CurrentUserDetailsState,
  UsersSortState,
  UsersPaginationState,
  UsersFilterState,
  PicState,
  UserEntity,
  UserInChargeEntity,
} from '@shared/redux/state/users';
import { initUsersState } from './initState/initUsersState';
import {
  RequestByUserIdPayload,
  UserCreatePicPayload,
  UserDeletePicPayload,
  UserPicRequestPayload,
} from '@shared/redux/actions/payloadTypes/userActionsPayloads';
import { RequestState } from '@shared/constants/request';
import { Page } from '@shared/api/dto/Page';
import { SortDirections } from '@shared/constants/SortDirections';

export function createUserModal(
  state: CreateUserModalState = initUsersState.createUserModal,
  action: Action,
): CreateUserModalState {
  switch (action.type) {
    case UserActionTypes.CREATE_USER_MODAL_OPEN: {
      return { isModalOpen: true };
    }
    case UserActionTypes.CREATE_USER_MODAL_CLOSE: {
      return { isModalOpen: false };
    }
  }
  return state;
}

export function currentUser(
  state: CurrentUserState = initUsersState.currentUser,
  action: ActionWithPayload<any>,
): CurrentUserState {
  switch (action.type) {
    case UserActionTypes.USER_DELETE_PIC_REQUEST: {
      const { userId }: RequestByUserIdPayload = action.payload;
      return {
        downloadPicRequested: false,
        deletePicRequested: true,
        deleteUserRequested: false,
        selectedUserId: userId,
      };
    }
    case UserActionTypes.USER_DELETE_PIC_REQUEST_CONFIRMED: {
      const { userId }: RequestByUserIdPayload = action.payload;
      return {
        downloadPicRequested: false,
        deletePicRequested: false,
        deleteUserRequested: false,
        selectedUserId: userId,
      };
    }
    case UserActionTypes.USER_CREATE_PIC_SUCCESS: {
      const { userId, pic }: UserCreatePicPayload = action.payload;
      return {
        deletePicRequested: false,
        downloadPicRequested: true,
        deleteUserRequested: false,
        selectedUserId: userId,
        pic,
      };
    }
    case UserActionTypes.USER_DELETE_REQUEST: {
      const { userId } = action.payload;
      return {
        deletePicRequested: false,
        downloadPicRequested: false,
        deleteUserRequested: true,
        selectedUserId: userId,
      };
    }
    case UserActionTypes.USER_DELETE_PIC_REQUEST_REJECTED:
    case UserActionTypes.USER_DOWNLOAD_PIC_CONFIRM:
    case UserActionTypes.USER_DOWNLOAD_PIC_REJECTED:
    case UserActionTypes.USER_DELETE_CONFIRM:
    case UserActionTypes.USER_DELETE_REJECTED: {
      return {
        deletePicRequested: false,
        downloadPicRequested: false,
        deleteUserRequested: false,
      };
    }
  }

  return state;
}

export function pics(
  state: PicState[] = initUsersState.entities.usersPage.pics,
  action: ActionWithPayload<any>,
): PicState[] {
  switch (action.type) {
    case UserActionTypes.FETCH_PAGE_OF_USERS_SUCCESS: {
      const a = action as ActionWithPayload<Page<UserEntity>>;
      if (!a.payload || !a.payload.items || a.payload.items.length === 0) return state;
      const result = a.payload.items;
      return result.map((user): PicState => {
        return {
          userId: user.id,
          requestState: RequestState.NOT_STARTED,
        };
      });
    }
    case UserActionTypes.USER_PIC_REQUEST: {
      const a = action as ActionWithPayload<RequestByUserIdPayload>;
      const { userId } = a.payload;
      const index = state.findIndex((value) => value.userId === userId);
      const newState = [...state];
      newState[index] = {
        userId,
        requestState: RequestState.PENDING,
      };
      return newState;
    }
    case UserActionTypes.USER_CREATE_PIC_SUCCESS: {
      const { userId, pic }: UserCreatePicPayload = action.payload;
      const index = state.findIndex((value) => value.userId === userId);
      const newState = [...state];
      newState[index] = {
        userId,
        requestState: RequestState.SUCCESS,
        picExist: true,
        pic,
      };
      return newState;
    }
    case UserActionTypes.USER_PIC_REQUEST_SUCCESS: {
      const { userId, picExist, pic }: UserPicRequestPayload = action.payload;
      const index = state.findIndex((value) => value.userId === userId);

      const newState = [...state];
      newState[index] = {
        userId,
        requestState: RequestState.SUCCESS,
        picExist,
        pic,
      };

      return newState;
    }
    case UserActionTypes.USER_DELETE_PIC_SUCCESS: {
      const { userId }: UserDeletePicPayload = action.payload;
      const index = state.findIndex((value) => value.userId === userId);
      const newState = [...state];
      newState[index] = {
        userId,
        requestState: RequestState.SUCCESS,
        picExist: false,
      };
      return newState;
    }
    case UserActionTypes.createUser.SUCCESS: {
      const a = action as ActionWithPayload<UserEntity>;
      const userId = a.payload.id;
      const newPic = {
        userId,
        requestState: RequestState.NOT_STARTED,
      };
      return [newPic, ...state];
    }
    default:
      return state;
  }
}

export function paginatedUserEntities(
  state: UserEntity[] = initUsersState.entities.usersPage.users,
  action: ActionWithPayload<any>,
): UserEntity[] {
  if (action.type === UserActionTypes.FETCH_PAGE_OF_USERS_SUCCESS) {
    const a = action as ActionWithPayload<Page<UserEntity>>;
    return a.payload.items;
  }
  if (action.type === UserActionTypes.createUser.SUCCESS) {
    const a = action as ActionWithPayload<UserEntity>;
    const user = a.payload;
    return [user, ...state];
  }
  return state;
}

export function usersInCharge(
  state: UserInChargeEntity[] = initUsersState.entities.usersInCharge.users,
  action: ActionWithPayload<UserInChargeEntity[]>,
): UserInChargeEntity[] {
  if (action.type === UserActionTypes.FETCH_ALL_USER_IN_CHARGE_SUCCESS) {
    return action.payload;
  }
  return state;
}

export function paginatedUsersRequestState(
  state: RequestState = RequestState.NOT_STARTED,
  action: Action,
): RequestState {
  switch (action.type) {
    case UserActionTypes.FETCH_PAGE_OF_USERS_REQUEST:
      return RequestState.PENDING;
    case UserActionTypes.FETCH_PAGE_OF_USERS_FAILURE:
      return RequestState.FAILED;
    case UserActionTypes.FETCH_PAGE_OF_USERS_SUCCESS:
      return RequestState.SUCCESS;
  }
  return state;
}

export function userInChargeRequestState(
  state: RequestState = RequestState.NOT_STARTED,
  action: ActionWithPayload<any>,
): RequestState {
  switch (action.type) {
    case UserActionTypes.FETCH_ALL_USER_IN_CHARGE_REQUEST:
      return RequestState.PENDING;
    case UserActionTypes.FETCH_ALL_USER_IN_CHARGE_FAILURE:
      return RequestState.FAILED;
    case UserActionTypes.FETCH_ALL_USER_IN_CHARGE_SUCCESS:
      return RequestState.SUCCESS;
  }
  return state;
}

export function currentUserDetails(
  state: CurrentUserDetailsState = initUsersState.currentUserDetails,
  action: UserActionTypes.UsersActions,
): CurrentUserDetailsState {
  switch (action.type) {
    case UserActionTypes.FETCH_USER_BY_ID_REQUEST:
      return {
        details: null,
        requestState: RequestState.PENDING,
      };

    case UserActionTypes.FETCH_USER_BY_ID_SUCCESS:
      return {
        details: action.payload,
        requestState: RequestState.SUCCESS,
      };

    case UserActionTypes.FETCH_USER_BY_ID_NOT_FOUND:
    case UserActionTypes.FETCH_USER_BY_ID_FAILURE:
      return {
        details: null,
        error: action.payload,
        requestState: RequestState.FAILED,
      };

    case UserActionTypes.RESET_USER_DETAILS_STATE:
      return initUsersState.currentUserDetails;

    default:
      return state;
  }
}

export function sort(state: UsersSortState = initUsersState.sort, action: ActionWithPayload<any>): UsersSortState {
  const { type, payload } = action;
  if (type === UserActionTypes.CHANGE_USERS_SORTING) {
    if (state.field === payload.field) {
      return {
        direction: state.direction === SortDirections.Descending ? SortDirections.Ascending : SortDirections.Descending,
        field: state.field,
      };
    } else if (payload.field !== undefined) {
      return {
        field: payload.field,
        direction:
          payload.defaultSortDirection === undefined ? SortDirections.Descending : payload.defaultSortDirection,
      };
    }
  }

  return state;
}

export function pagination(
  state: UsersPaginationState = initUsersState.pagination,
  action: ActionWithPayload<any>,
): UsersPaginationState {
  switch (action.type) {
    case UserActionTypes.FETCH_PAGE_OF_USERS_SUCCESS: {
      const { pageNumber, totalCount } = action.payload;
      return {
        ...state,
        page: pageNumber,
        totalEntriesCount: totalCount,
      };
    }
  }
  return state;
}

export function filter(
  state: UsersFilterState = initUsersState.filter,
  action: ActionWithPayload<any>,
): UsersFilterState {
  const { type, payload } = action;
  switch (type) {
    case UserActionTypes.SET_USERS_FILTER:
      return {
        searchQuery: payload.searchQuery,
      };

    default:
      return state;
  }
}

const entities = combineReducers({
  usersInCharge: combineReducers({
    users: usersInCharge,
    requestState: userInChargeRequestState,
  }),
  usersPage: combineReducers({
    users: paginatedUserEntities,
    requestState: paginatedUsersRequestState,
    pics,
  }),
});

export const users = combineReducers({
  createUserModal,
  currentUser,
  entities,
  currentUserDetails,
  sort,
  filter,
  pagination,
});
