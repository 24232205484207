import * as PatientActionTypes from '@shared/redux/actions/patientActions/patient-actions';
import * as ReduxForms from '@shared/constants/reduxFormNames';
import { emptyCountry, PossibleCountryCode } from '@shared/util/phone';
import { getCurrentOrganisation } from '@shared/redux/actions/organisation-actions';
import { ACCOUNT_DETAILS_REQUEST_SUCCESS } from '@shared/redux/actions/users-actions';
import { AllActions } from '@shared/redux/actions';
import { REDUX_FORM_CHANGE } from '@shared/redux/actions/redux-form-actions';

export const initPatientFormState = {
  additionalPatientId: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  startDateOfDataStorage: null as string | null,
  sex: 0,
  email: '',
  phone: {
    number: '',
    countryCode: emptyCountry.iso2code as PossibleCountryCode,
  },
  address: '',
  isSubmitting: false,
  assignAfterSave: true,
  usersInCharge: [] as string[],
  defaultUserId: null as string | null,
  defaultCountryCode: emptyCountry.iso2code as PossibleCountryCode,
  categories: [],
};

export type PatientFormState = typeof initPatientFormState;

interface PatientFormChangeState {
  initial: PatientFormState;
  values?: {
    defaultUserId: string;
  };
}

export function resetCreatePatientFormPlugin(
  state: PatientFormChangeState | undefined,
  action: PatientActionTypes.PatientActions,
): PatientFormChangeState | undefined {
  if (action.type === PatientActionTypes.RESET_PATIENT_FORM) {
    // According to redux-form code (it check if "values" exists) it is possible that values
    // can be undefined so check it before and in some cases state can be undefined.
    if (state?.values) {
      initPatientFormState.defaultUserId = state.values.defaultUserId;
      initPatientFormState.usersInCharge = [state.values.defaultUserId];
      initPatientFormState.phone = {
        number: '',
        countryCode: state.initial.defaultCountryCode,
      };
      initPatientFormState.defaultCountryCode = state.initial.defaultCountryCode;
      state.initial = initPatientFormState;
    }
  }

  return state;
}

export function patientForm(state: PatientFormState = { ...initPatientFormState }, action: AllActions) {
  switch (action.type) {
    case REDUX_FORM_CHANGE: {
      const {
        meta: { form, field },
        payload,
      } = action;
      if (form === ReduxForms.CREATE_PATIENT_FORM) {
        const fields = field.split('.');
        if (fields.length > 1) {
          // use any to allow to set fields.
          const newState: any = { ...state };
          // for copying of phone object { phone: {number, countryCode} }
          newState[fields[0]][fields[1]] = payload;
          return { ...newState };
        } else {
          return {
            ...state,
            [field]: payload,
          };
        }
      }
      return state;
    }
    case PatientActionTypes.RESET_PATIENT_FORM:
      return {
        ...initPatientFormState,
        phone: {
          number: '',
          countryCode: state.defaultCountryCode,
        },
        defaultCountryCode: state.defaultCountryCode,
      };
    case getCurrentOrganisation.SUCCESS: {
      const { countryCode } = action.payload;
      return {
        ...state,
        defaultCountryCode: countryCode,
        phone: {
          number: state.phone.number,
          countryCode,
        },
      };
    }
    case PatientActionTypes.LOCK_PATIENT_FORM:
      return { ...state, isSubmitting: true };
    case PatientActionTypes.UNLOCK_PATIENT_FORM:
      return { ...state, isSubmitting: false };
    case ACCOUNT_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        defaultUserId: action.payload.id,
        usersInCharge: [action.payload.id],
      };
  }
  return state;
}
