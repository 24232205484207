import { FiltersState } from '@shared/redux/state/patients';
import { SharingStatusFilters } from '@shared/constants/filters';
import { initPatientsRootState } from '@shared/redux/reducers/initState/initPatientsRootState';
import { FilterValue } from '@shared/types/filteringTypes';

export const THERAPY_SET_FILTER = 'THERAPY_SET_FILTER';
export const THERAPY_REMOVE_FILTER = 'THERAPY_REMOVE_FILTER';
export const THERAPY_CLEAR_FILTERS = 'THERAPY_CLEAR_FILTERS';
export const THERAPY_CHANGE_SHARING_STATUS = 'THERAPY_CHANGE_SHARING_STATUS';
export const THERAPY_CHANGE_SHARED_TO_ME = 'THERAPY_CHANGE_SHARED_TO_ME';
export const THERAPY_SHARE_TO_TENANT = 'THERAPY_SHARE_TO_TENANT';
export const THERAPY_CHANGE_USERS_IN_CHARGE = 'THERAPY_CHANGE_USERS_IN_CHARGE';
export const THERAPY_SELECT_ALL = 'THERAPY_SELECT_ALL';
export const THERAPY_SELECT_NONE = 'THERAPY_SELECT_NONE';
export const THERAPY_SET_FILTERS_STATE = 'THERAPY_SET_FILTERS_STATE';

export const setFilter = (filterKey: keyof FiltersState, filterValue: string | number | null) =>
  ({
    type: THERAPY_SET_FILTER,
    payload: {
      filterKey,
      filterValue,
    },
  } as const);

export const changeSharingStatus = (filterValue: string) =>
  ({
    type: THERAPY_CHANGE_SHARING_STATUS,
    payload: {
      filterValue,
    },
  } as const);

export const changeSharedToMe = (filterValue: string) =>
  ({
    type: THERAPY_CHANGE_SHARED_TO_ME,
    payload: {
      filterValue,
    },
  } as const);

export const shareToTenant = (filterValue: string) =>
  ({
    type: THERAPY_SHARE_TO_TENANT,
    payload: {
      filterValue,
    },
  } as const);

export const changeUsersInCharge = (filterValue: string) =>
  ({
    type: THERAPY_CHANGE_USERS_IN_CHARGE,
    payload: {
      filterValue,
    },
  } as const);

export const clearFilters = () =>
  ({
    type: THERAPY_CLEAR_FILTERS,
    payload: initPatientsRootState.filters,
  } as const);

export const removeFilter = (filterKey: keyof FiltersState) =>
  ({
    type: THERAPY_REMOVE_FILTER,
    payload: { filterKey },
  } as const);

export const selectAll = (filterKey: keyof FiltersState, options: FilterValue<FiltersState[keyof FiltersState]>[]) =>
  ({
    type: THERAPY_SELECT_ALL,
    payload: {
      filterKey,
      filterValue: options.filter((opt) => opt != null),
    },
  } as const);

export const selectNone = (filterKey: keyof FiltersState) =>
  ({
    type: THERAPY_SELECT_NONE,
    payload: {
      filterKey,
      filterValue: [],
    },
  } as const);

export const setFiltersState = (filtersState: FiltersState) =>
  ({
    type: THERAPY_SET_FILTERS_STATE,
    payload: filtersState,
  } as const);

export type SetFilter = ReturnType<typeof setFilter>;
export type ChangeSharingStatus = ReturnType<typeof changeSharingStatus>;
export type ChangeSharedToMe = ReturnType<typeof changeSharedToMe>;
export type ShareToTenant = ReturnType<typeof shareToTenant>;
export type ChangeUsersInCharge = ReturnType<typeof changeUsersInCharge>;
export type ClearFilters = ReturnType<typeof clearFilters>;
export type RemoveFilter = ReturnType<typeof removeFilter>;
export type SelectAll = ReturnType<typeof selectAll>;
export type SelectNone = ReturnType<typeof selectNone>;
export type SetFiltersState = ReturnType<typeof setFiltersState>;

export type PatientsTherapyFiltersAction =
  | SetFilter
  | ChangeSharingStatus
  | ChangeSharedToMe
  | ShareToTenant
  | ChangeUsersInCharge
  | ClearFilters
  | RemoveFilter
  | SelectAll
  | SelectNone
  | SetFiltersState;
