import { UsersState } from '@shared/redux/state/users';
import { RequestState } from '@shared/constants/request';
import { UserSortingFields } from '@shared/constants/sortingFields';
import { SortDirections } from '@shared/constants/SortDirections';

export const initUsersState: UsersState = {
  createUserModal: {
    isModalOpen: false,
  },
  currentUser: {
    deletePicRequested: false,
    downloadPicRequested: false,
    deleteUserRequested: false,
  },
  entities: {
    usersInCharge: {
      users: [],
      requestState: RequestState.NOT_STARTED,
    },
    usersPage: {
      users: [],
      requestState: RequestState.NOT_STARTED,
      pics: [],
    },
  },
  currentUserDetails: {
    details: null,
    requestState: RequestState.NOT_STARTED,
  },
  sort: {
    field: UserSortingFields.LastName,
    direction: SortDirections.Ascending,
  },
  filter: {
    searchQuery: '',
  },
  pagination: {
    page: 0,
    totalEntriesCount: 0,
  },
};
