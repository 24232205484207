import i18next from 'i18next';

/**
 * Map with { country ISO code - localization key}
 * IMPORTANT: Country name abbreviation should be ISO2, use only ISO2,
 * otherwise you will get a conflict with backend and such country wouldn't be found.
 */
export const supportedCountryToLocalizedKey = {
  ZZ: 'Country_Name_Other',
  BR: 'Country_Name_Brazil',
  CL: 'Country_Name_Chile',
  CO: 'Country_Name_Colombia',
  CR: 'Country_Name_CostaRica',
  FR: 'Country_Name_France',
  DE: 'Country_Name_Germany',
  JP: 'Country_Name_Japan',
  MX: 'Country_Name_Mexico',
  PA: 'Country_Name_Panama',
  CH: 'Country_Name_Switzerland',
  IL: 'Country_Name_Israel',
  SI: 'Country_Name_Slovenia',
  MA: 'Country_Name_Morocco',
  CZ: 'Country_Name_Czechia',
  PL: 'Country_Name_Poland',
  IT: 'Country_Name_Italy',
  SK: 'Country_Name_Slovakia',
  DK: 'Country_Name_Denmark',
  KR: 'Country_Name_Korea',
  NL: 'Country_Name_Netherlands',
  LV: 'Country_Name_Latvia',
  GR: 'Country_Name_Greece',
  BG: 'Country_Name_Bulgaria',
  TR: 'Country_Name_Turkey',
  IE: 'Country_Name_Ireland',
  GB: 'Country_Name_UnitedKingdom',
  AU: 'Country_Name_Australia',
  HK: 'Country_Name_HongKong',
  LB: 'Country_Name_Lebanon',
  MU: 'Country_Name_Mauritius',
  NZ: 'Country_Name_NewZealand',
  NO: 'Country_Name_Norway',
  RO: 'Country_Name_Romania',
  SE: 'Country_Name_Sweden',
  CA: 'Country_Name_Canada',
  EE: 'Country_Name_Estonia',
  US: 'Country_Name_USA',
  SA: 'Country_Name_SaudiArabia',
} as const;

export type SupportedCountries = keyof typeof supportedCountryToLocalizedKey;

/**
 * Country codes used in code. Are used as indices for countryTypeToLocalizedKey.
 * IMPORTANT: Country name abbreviation should be ISO2, use only ISO2,
 * otherwise you will get a conflict with backend and such country wouldn't be found.
 */
export const supportedCountryKeys = {
  Other: 'ZZ',
  Germany: 'DE',
} as const;

export const localizedCountryTypedString = (country: SupportedCountries): string => {
  return i18next.t(supportedCountryToLocalizedKey[country]);
};
