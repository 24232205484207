import { AxiosRequestConfig } from 'axios';

import { BaseAPI } from '@shared/api/base-api';
import { Page } from '@shared/api/dto/Page';
import { SupportedCountries } from '@shared/constants/countries';
import { SortDirections } from '@shared/constants/SortDirections';
import { OrganisationSortingFields } from '@shared/constants/sortingFields';
import { UserRoleTypes } from '@shared/constants/UserRoleTypes';
import { queryBuilder } from '@shared/util/url-helper';

export interface CurrentOrganisationInfoDto {
  tenant: string;
  name: string;
  countryCode: SupportedCountries;
  allowTeleConfiguration: boolean;
  confirmSettingsRequired: boolean;
  twoFactorEnabled: boolean;
  timezoneInfo: TimezoneDto;
}

/**
 * Dto to provide available timezone for webApp.
 * @export
 * @interface TimezoneDto
 */
export interface TimezoneDto {
  /**
   * String, which will be displayed in list of timezones for the user.
   * @type {string}
   * @memberOf TimezoneDto
   */
  displayName: string;
  /**
   * The value which represents id of timezone. (TimeZoneInfo.Id)
   * @type {string}
   * @memberOf TimezoneDto
   */
  id: string;
  /**
   * The offset between the time in organisation's timezone and UTC.
   * @type {number}
   * @memberOf TimezoneDto
   */
  timezoneOffset_Minutes: number;
}

export interface NewOrganisationModelBase {
  /**
   * Name of the organisation
   * @type {string}
   * @memberof NewOrganisationModel
   */
  name: string;
  /**
   * The tenant of the organisation
   * @type {string}
   * @memberof NewOrganisationModel
   */
  tenant: string;
  /**
   * The contact information
   * @type {string}
   * @memberof NewOrganisationModel
   */
  contact: string;
  /**
   * The country code
   * @type {string}
   * @memberof NewOrganisationModel
   */
  countryCode: string;
  /**
   * Specifies, if the contract with this organisation has been signed.
   * @type {boolean}
   * @memberof NewOrganisationModel
   */
  isContractSigned: boolean;
  /**
   * If external identity provider is NOT used, specifies,
   * if two factor authentication is enforced for this organisation.
   * @type {boolean}
   * @memberof NewOrganisationModel
   */
  twoFactorEnabled: boolean;
  /**
   * true: in case if the organisation is allowed to change configuration of devices.
   * false: the organisation is not allowed to change configuration
   */
  allowTeleConfiguration: boolean;
  /**
   * Organization timezone id.
   * E.g. 'Europe/Malta' or 'CET'.
   */
  timezoneId: string;
  /* Specifies, if external identity provider is used for the organisation. */
  isExternalLoginUsed: boolean;
  /* If external identity provider is used, contains tenant ID for identity provider. */
  externalLoginTenantId?: string;
  /* If external identity provider is used, contains email of identity provider manager.*/
  externalLoginAdminEmail?: string;
  /* If external identity provider is used, contains default user roles granted to new created users. */
  defaultUserRole?: UserRoleTypes;
}

/**
 * Dto being used to create a new organisation
 * @export
 * @interface NewOrganisationModel
 */
export interface NewOrganisationModel extends NewOrganisationModelBase {
  /**
   * Specifies, if the contract with this organisation has been signed.
   * @type {boolean}
   * @memberof NewOrganisationModel
   */
  isContractSigned: boolean;
}

/**
 * Dto being used to edit an existing organisation
 * @export
 * @interface NewOrganisationModel
 */
export interface EditOrganisationModel extends NewOrganisationModelBase {}

/**
 * DTO containing information about an organisation and the manager.
 */
export interface OrganisationWithManagerModel {
  tenant: string;
  name: string;
  countryCode: SupportedCountries;
  allowTeleConfiguration: boolean;
  contact: string;
  createdAt: string;
  timezoneInfo: TimezoneDto;
  twoFactorEnabled: boolean;
  isActivated: boolean;
  isExternalLoginUsed: boolean;
  externalLoginManagerEmail: string | null;
  externalLoginTenantId: string | null;
  defaultRoles: string | null;
  managerMail: string | null;
  managerMailConfirmed: boolean;
}

/**
 * Dto containing basic information of an organisation, available through anonymous endpoint.
 * @export
 * @interface AnonymousOrganisationSummary
 */
export interface AnonymousOrganisationSummary {
  /**
   * Indicates whether the organisation exists.
   * @type {boolean}
   * @memberof AnonymousOrganisationSummary
   */
  exists: string;
  /**
   *  Indicates if organisation uses external identity provider (e.g. AAD).
   * @type {boolean}
   * @memberof AnonymousOrganisationSummary
   */
  isExternalLoginUsed: boolean;
}

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApi = (baseApi: BaseAPI) => {
  return {
    /**
     * Checks by tenant if an organisation exists.
     * @param {string} tenant the tenant to check.
     * @returns boolean indicating whether organisation exists.
     */
    async getOrganisationSummary(tenant: string) {
      const result = await baseApi.get<AnonymousOrganisationSummary>(`/api/organisations/info/${tenant}`);

      // Axios typing has a bug, it returns string for header value instead of string | undefined,
      // so set it explicitly.
      const language = result.headers['accept-language'] as string | undefined;

      return {
        exists: result.data.exists,
        isExternalLoginUsed: result.data.isExternalLoginUsed,
        language,
      };
    },

    /**
     * Gets the current organisation.
     */
    async getCurrent(): Promise<CurrentOrganisationInfoDto> {
      const requestPath = '/api/organisations/current';
      const { data } = await baseApi.get<CurrentOrganisationInfoDto>(requestPath);
      return data;
    },

    /**
     * Returns value indicating if terms of use are signed for organisation.
     * @param [tenant] Organisation tenant.
     */
    async getTermsOfUseStatus(tenant: string): Promise<boolean> {
      const requestPath = '/api/organisations/terms-of-use';
      const { data } = await baseApi.get<boolean>(requestPath, { tenant });
      return data;
    },

    /**
     * Gets a page of organisations.
     * @param {number} [page] the page number to retrieve;
     * @param {number} [count] the amount of users on a page;
     * @param {SortDirections} [direction] the sorting direction;
     * @param {OrganisationSortingFields} [field] the field to sort;
     * @param {string} [search] the search query.
     * @returns the page of organisations.
     */
    async getPage(
      page?: number,
      count?: number,
      direction?: SortDirections,
      field?: OrganisationSortingFields,
      search?: string,
      loginType?: 'All' | 'Internal' | 'EntraId',
      countryCodes?: SupportedCountries[],
      options?: AxiosRequestConfig,
    ): Promise<Page<OrganisationWithManagerModel>> {
      const query = queryBuilder()
        .append('page', page)
        .append('count', count)
        .append('direction', direction)
        .append('field', field)
        .append('search', search)
        .append('loginType', loginType)
        .append('countryCode', countryCodes)
        .build();

      const { data } = await baseApi.get<Page<OrganisationWithManagerModel>>(
        `/api/organisations${query}`,
        undefined,
        options,
      );
      return data;
    },

    /**
     * Deletes the organisation.
     * @param {string} tenant The tenant of organisation to delete.
     */
    async delete(tenant: string): Promise<void> {
      const requestPath = `/api/organisations/${tenant}`;
      await baseApi.delete(requestPath);
    },

    /**
     * Gets list of available Iana timezones.
     * @returns list of available timezones.
     */
    async getAvailableTimezones(options?: AxiosRequestConfig): Promise<TimezoneDto[]> {
      const requestPath = '/api/organisations/available-timezones';
      const { data } = await baseApi.get<TimezoneDto[]>(requestPath, {}, options);
      return data;
    },

    /**
     * Creates a new organisation.
     * @param {NewOrganisationModel} [dto] the DTO containing the
     * information about the organisation to create.
     * @returns the created organisation if successful.
     */
    async add(dto: NewOrganisationModel): Promise<OrganisationWithManagerModel> {
      const requestPath = '/api/organisations/';
      const { data } = await baseApi.post<OrganisationWithManagerModel>(requestPath, undefined, dto);
      return data;
    },

    /**
     * Updates the organisation data.
     * @param {string} dto DTO containing data to update.
     */
    async edit(dto: EditOrganisationModel): Promise<OrganisationWithManagerModel> {
      const requestPath = '/api/organisations/';
      const { data } = await baseApi.put<OrganisationWithManagerModel>(requestPath, undefined, dto);
      return data;
    },
  };
};

export const OrganisationApiFactory = (baseApi: BaseAPI) => OrganisationApi(baseApi);
