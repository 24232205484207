import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as url from 'url';

import { postProcessAxiosError, prepareRequestAndFillFormData } from '@shared/api/base-api';
import { DevicePageRequestParams, UpdateOutOfUseRequestParams } from '@shared/api/core-types';
import { DeviceAssignmentInfoDto, DeviceAssignmentItemDto } from '@shared/api/dto/DeviceAssignmentDto';
import { DeviceDataSource } from '@shared/api/dto/DeviceDataSource';
import { DeviceGeneration } from '@shared/api/dto/DeviceGeneration';
import { DeviceOutgoingStates } from '@shared/api/dto/DeviceOutgoingStates';
import { ReassignDeviceDto } from '@shared/api/dto/DeviceReassignmentDto';
import { UpdateOutOfUseDto } from '@shared/api/dto/DeviceUpdateOutOfUseDto';
import { Page } from '@shared/api/dto/Page';
import { PATH_TO_CORE_API } from '@shared/constants';
import { PreregisteredDevicesUploadData } from '@shared/types/devicePreRegistrationTypes';

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_CORE_API,
};

export interface AssignedPatientDto {
  lastName: string;
  firstName: string;
  prismaCloudId: string;
}

export interface ModemInfo {
  iccid: string;
  imei: string;
}

export enum P5SendDataTriggerStatus {
  /*
   * Send data trigger is not supported, because of invalid generation.
   */
  NotSupportedByGeneration = 'NotSupportedByGeneration',
  /*
   * Send data trigger is not supported because device is not online.
   */
  NotOnline = 'NotOnline',
  /*
   * Send data trigger supported.
   */
  Supported = 'Supported',
  /*
   * Send all data was triggered.
   */
  TriggeredSendAll = 'TriggeredSendAll',
  /*
   * Send current data was triggered.
   */
  TriggeredSendCurrent = 'TriggeredSendCurrent',
}

export interface DeviceDto {
  serialNumber: string;
  generation: DeviceGeneration;
  brandName: string;
  currentBindingId: string;
  firmwareVersion: string;
  /*
   * The latest date when data was received for device via LastDeviceDataSource.
   * Null in case if LastDeviceDataSource is NoData or PreRegistered.
   */
  lastDataReceivedAt: string | null;
  lastDeviceDataSource: DeviceDataSource;
  modemInfo: ModemInfo | null;
  assignedPatient: AssignedPatientDto;
  isDeviceTimeWrong: boolean;
  isMissingModemTransmission: boolean;
  isMissingData: boolean;
  missingDataThresholdInDays?: number;
  isOutOfUse: boolean;
  isTelemonitoringEnabled: boolean;
  supportsTelemonitoringSwitch: boolean;
  faultedTherapyConfiguration: {
    faultedAt: string;
    lastFaultedState: DeviceOutgoingStates;
  } | null;
  hasAnyPatientAppUser: boolean;
  lastSendDataTriggeredAt: string;
  sendDataTrigger: P5SendDataTriggerStatus;
}

export interface DevicePageDto extends Page<DeviceDto> {
  unfilteredTotalDeviceCount: number;
}

export const DevicesApi = {
  async getDevice(serialNumber: string, options?: AxiosRequestConfig): Promise<DeviceDto> {
    const response: AxiosResponse<DeviceDto> = await axios.get(`/api/devices/${serialNumber}`, {
      ...defaultOpts,
      ...options,
    });
    return response.data;
  },
  async getAll(options?: AxiosRequestConfig): Promise<DeviceAssignmentItemDto[]> {
    const response: AxiosResponse<DeviceAssignmentItemDto[]> = await axios.get('/api/devices', {
      ...defaultOpts,
      ...options,
    });

    return response.data;
  },
  async getPage(
    searchQuery: string | null,
    requestParams: DevicePageRequestParams,
    options?: AxiosRequestConfig,
  ): Promise<DevicePageDto> {
    const params = url.format({ query: requestParams as unknown as any });
    const response: AxiosResponse<DevicePageDto> = await axios.post(
      `/api/devices/page${params}`,
      { text: searchQuery },
      { ...defaultOpts, ...options },
    );

    return response.data;
  },
  async getAssignmentInfo(deviceId: number, options?: AxiosRequestConfig): Promise<DeviceAssignmentInfoDto> {
    const response = await axios.get(`/api/devices/assignment/${deviceId}`, { ...defaultOpts, ...options });
    return response.data;
  },
  async updateOutOfUse(params: UpdateOutOfUseRequestParams, options?: AxiosRequestConfig): Promise<UpdateOutOfUseDto> {
    const response = await axios.post('/api/devices/updateOutOfUse', params, { ...defaultOpts, ...options });
    return response.data;
  },
  async reassignDevice(dto: ReassignDeviceDto, options?: AxiosRequestConfig): Promise<void> {
    await axios.post('/api/devices/reassign', dto, { ...defaultOpts, ...options });
  },
  async uploadPreregisteredDevices(file: any, options?: AxiosRequestConfig): Promise<PreregisteredDevicesUploadData[]> {
    const localVarAxiosArgs = prepareRequestAndFillFormData(file, options, '/api/devices/pre-register');
    const baseURL = defaultOpts.baseURL;
    const axiosRequestArgs = {
      ...localVarAxiosArgs.options,
      url: baseURL + localVarAxiosArgs.url,
    };
    try {
      const result: AxiosResponse<PreregisteredDevicesUploadData[]> = await axios.request(axiosRequestArgs);
      return result.data;
    } catch (error) {
      return postProcessAxiosError(error);
    }
  },
  async movePreRegisteredDevices(file: any, options?: AxiosRequestConfig): Promise<void> {
    const localVarAxiosArgs = prepareRequestAndFillFormData(file, options, '/api/devices/move-pre-registered');
    const baseURL = defaultOpts.baseURL;
    const axiosRequestArgs = {
      ...localVarAxiosArgs.options,
      url: baseURL + localVarAxiosArgs.url,
    };
    try {
      await axios.request(axiosRequestArgs);
    } catch (error) {
      return postProcessAxiosError(error);
    }
  },
  async switchTelemonitoring(
    serialNumber: string,
    deviceGeneration: DeviceGeneration,
    isEnabled: boolean,
    options?: AxiosRequestConfig,
  ): Promise<void> {
    await axios.post(
      `/api/devices/switch-telemonitoring/${serialNumber}${url.format({ query: { deviceGeneration, isEnabled } })}`,
      {},
      { ...defaultOpts, ...options },
    );
  },
  async getAllDevicesAsCsv(options?: AxiosRequestConfig): Promise<ArrayBuffer> {
    const response: AxiosResponse<ArrayBuffer> = await axios.get('/api/devices/export', {
      ...defaultOpts,
      ...options,
    });

    return response.data;
  },
  async triggerSendAll(serialNumber: string, options?: AxiosRequestConfig): Promise<AxiosResponse> {
    const response = await axios.post(
      `/api/devices/trigger-send-all/${serialNumber}`,
      {},
      { ...defaultOpts, ...options },
    );

    return response;
  },
  async triggerSendCurrent(serialNumber: string, options?: AxiosRequestConfig): Promise<AxiosResponse> {
    const response = await axios.post(
      `/api/devices/trigger-send-current/${serialNumber}`,
      {},
      { ...defaultOpts, ...options },
    );

    return response;
  },
  async deactivateDevice(serialNumber: string, options?: AxiosRequestConfig): Promise<AxiosResponse> {
    const response = await axios.delete(`/api/devices/deactivate/${serialNumber}`, { ...defaultOpts, ...options });
    return response;
  },
};
