import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import { postProcessAxiosError } from '../base-api';
import { DeviceType } from '@shared/api/dto/DeviceType';
import { PATH_TO_CORE_API } from '@shared/constants';

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_CORE_API,
};

/**
 * Information of a device binding
 * @export
 * @interface DeviceBindingInfoDto
 */
export interface DeviceBindingInfoDto {
  /**
   * Serial number of the device
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  deviceSerialNumber: string;
  /**
   * DeviceType.
   * @type {DeviceType}
   * @memberof DeviceBindingInfoDto
   */
  deviceType: DeviceType;
  /**
   * Device brand name.
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  deviceBrandName: string;
  /**
   * Last known device firmware version.
   * Null in case if the firmware version is unknown.
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  deviceFirmwareVersion: string;
  /**
   * The prisma CLOUD ID of the patient or null in case if no patient is assigned
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  patientPrismaCloudId: string | null;
  /**
   * First name of the assigned patient or null in case if no patient is assigned
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  patientFirstName: string | null;
  /**
   * Last name of the assigned patient or null in case if no patient is assigned
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  patientLastName: string | null;
  /**
   * The current binding id of assigned patient or null in case if no patient is assigned
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  patientsCurrentBindingId: string | null;
  /**
   * Organisation name to which patient and device belongs to.
   * @type {string}
   * @memberof DeviceBindingInfoDto
   */
  ownerOrganisationName: string;
  /**
   * Tenant of the organisation to which patient and device belong.
   */
  ownerOrganisationTenant: string;

  /**
   * Time when the device was unassigned from the patient.
   * null in case if the device is currently assigned to the patient.
   */
  unassignedAt: string | null;
}

export const DeviceBindingsApi = {
  async getDeviceBindingWithPatientInfo(deviceBindingId: string, options?: AxiosRequestConfig) {
    try {
      const response: AxiosResponse<DeviceBindingInfoDto> = await axios.get(
        `/api/DeviceBindings/with-patient/${deviceBindingId}`,
        {
          ...defaultOpts,
          ...options,
        },
      );
      return response.data;
    } catch (e: any) {
      postProcessAxiosError(e);
    }
  },
};
