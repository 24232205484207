import * as ReduxForms from '@shared/constants/reduxFormNames';
import * as SettingsActions from '@shared/redux/actions/settings-actions';
import * as OrganisationActionTypes from '@shared/redux/actions/organisation-actions';
import * as UserActionTypes from '@shared/redux/actions/users-actions';
import { initUserFormState } from './initState/initUserFormState';
import { AllActions } from '@shared/redux/actions';
import { REDUX_FORM_CHANGE, REDUX_FORM_RESET } from '@shared/redux/actions/redux-form-actions';
import { userFormType } from '@shared/constants/reduxFormNames';

type NonFunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

type FieldType = NonFunctionPropertyNames<typeof initUserFormState>;

function getInitialUserFormState(currentState: typeof initUserFormState) {
  return {
    ...initUserFormState,
    phone: {
      number: '',
      countryCode: currentState.defaultCountryCode,
    },
    twoFactorEnabled: currentState.initialTwoFactorEnabled,
    defaultCountryCode: currentState.defaultCountryCode,
    initialTwoFactorEnabled: currentState.initialTwoFactorEnabled,
  };
}

function userFormCommon(
  state: typeof initUserFormState = { ...initUserFormState },
  action: AllActions,
  formName: userFormType,
): typeof initUserFormState {
  switch (action.type) {
    case REDUX_FORM_CHANGE: {
      const {
        meta: { form, field },
        payload,
      } = action;
      if (form === formName) {
        const fields: FieldType[] = field.split('.') as FieldType[];
        if (fields.length > 1) {
          const newState = { ...state };
          // for copying of phone object { phone: {number, countryCode} }
          (newState[fields[0]] as any)[fields[1]] = payload;
          return { ...newState };
        } else {
          return { ...state, [field]: payload };
        }
      }
      return state;
    }
    case REDUX_FORM_RESET: {
      const form = action.meta.form;
      if (form === formName) {
        return getInitialUserFormState(state);
      }
      return state;
    }
    case SettingsActions.SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        twoFactorEnabled: state.twoFactorEnabled && action.payload.isTwoFactorAuthenticationEnabled,
        initialTwoFactorEnabled: action.payload.isTwoFactorAuthenticationEnabled,
      };
    }
    case SettingsActions.TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS: {
      return {
        ...state,
        twoFactorEnabled: true,
        initialTwoFactorEnabled: true,
      };
    }
    case SettingsActions.TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS: {
      return {
        ...state,
        twoFactorEnabled: false,
        initialTwoFactorEnabled: false,
      };
    }
    case OrganisationActionTypes.getCurrentOrganisation.SUCCESS: {
      const { countryCode } = (action as any).payload;
      return {
        ...state,
        defaultCountryCode: countryCode,
        phone: {
          number: state.phone.number,
          countryCode,
        },
      };
    }
    case UserActionTypes.LOCK_USER_FORM: {
      return { ...state, isSubmitting: true };
    }
    case UserActionTypes.UNLOCK_USER_FORM: {
      return { ...state, isSubmitting: false };
    }
  }

  return state;
}

export function userForm(
  state: typeof initUserFormState = { ...initUserFormState },
  action: AllActions,
): typeof initUserFormState {
  return userFormCommon(state, action, ReduxForms.USER_FORM);
}

export function orgManagerForm(
  state: typeof initUserFormState = { ...initUserFormState },
  action: AllActions,
): typeof initUserFormState {
  return userFormCommon(state, action, ReduxForms.ORG_MANAGER_FORM);
}
