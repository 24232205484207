import { GetDisabledLanguages } from '@shared/config/feature-flags';

export interface Culture {
  languageName: string;
  languageCulture: string;
  dayJSLocale: string;
}

export const getCultures = (): Culture[] => {
  const disabledLanguages = GetDisabledLanguages();

  const allCultures: Culture[] = [
    {
      languageName: 'English (United Kingdom)',
      languageCulture: 'en',
      dayJSLocale: 'en-gb',
    },
    {
      languageName: 'English (United States)',
      languageCulture: 'en-us',
      dayJSLocale: 'en',
    },
    {
      languageName: 'Français',
      languageCulture: 'fr',
      dayJSLocale: 'fr',
    },
    {
      languageName: 'Deutsch',
      languageCulture: 'de',
      dayJSLocale: 'de',
    },
    {
      languageName: '中文',
      languageCulture: 'zh',
      dayJSLocale: 'zh-cn',
    },
    {
      languageName: '日本語',
      languageCulture: 'ja',
      dayJSLocale: 'ja',
    },
    {
      languageName: 'Español',
      languageCulture: 'es',
      dayJSLocale: 'es-mx',
    },
    {
      languageName: 'Português',
      languageCulture: 'pt',
      dayJSLocale: 'pt-br',
    },
    {
      languageName: 'Česky',
      languageCulture: 'cs',
      dayJSLocale: 'cs',
    },
    {
      languageName: 'Slovenský',
      languageCulture: 'sk',
      dayJSLocale: 'sk',
    },
    {
      languageName: 'Italiano',
      languageCulture: 'it',
      dayJSLocale: 'it',
    },
    {
      languageName: 'Dansk',
      languageCulture: 'da',
      dayJSLocale: 'da',
    },
    {
      languageName: 'Polski',
      languageCulture: 'pl',
      dayJSLocale: 'pl',
    },
    {
      languageName: '한국어',
      languageCulture: 'ko',
      dayJSLocale: 'ko',
    },
    {
      languageName: 'Nederlands',
      languageCulture: 'nl',
      dayJSLocale: 'nl',
    },
    {
      languageName: 'Latviešu',
      languageCulture: 'lv',
      dayJSLocale: 'lv',
    },
    {
      languageName: 'Ελληνικά',
      languageCulture: 'el',
      dayJSLocale: 'el',
    },
    {
      languageName: 'Български',
      languageCulture: 'bg',
      dayJSLocale: 'bg',
    },
    {
      languageName: 'Türkçe',
      languageCulture: 'tr',
      dayJSLocale: 'tr',
    },
    {
      languageName: 'Svenska',
      languageCulture: 'sv',
      dayJSLocale: 'sv',
    },
    {
      languageName: 'Eesti',
      languageCulture: 'et',
      dayJSLocale: 'et',
    },
    {
      languageName: 'Norsk',
      languageCulture: 'no',
      dayJSLocale: 'nb',
    },
  ];

  return disabledLanguages
    ? allCultures.filter((x) => disabledLanguages.indexOf(x.languageCulture) === -1)
    : allCultures;
};
