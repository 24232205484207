import { FiltersState } from '@shared/redux/state/patients';
import {
  DeviceAssignmentFilters,
  PatientCategoryFilterKey,
  PatientNoteFilters,
  SharingStatusFilters,
  TeleSettingsFilters,
} from '@shared/constants/filters';
import { PatientFilterCategory } from '@shared/constants/PatientFilterCategory';

export function setDefaultValueForFiltersByKey(
  filterKey: keyof FiltersState,
  filtersState: FiltersState,
): FiltersState {
  switch (filterKey) {
    case 'deviceAssignmentFilter':
      return {
        ...filtersState,
        deviceAssignmentFilter: DeviceAssignmentFilters.Empty,
      };
    case 'patientNoteFilter': {
      return {
        ...filtersState,
        patientNoteFilter: PatientNoteFilters.Empty,
      };
    }
    case 'sharedByMeToTenant':
      return {
        ...filtersState,
        sharedByMeToTenant: '',
      };
    case 'sharedToMeFromTenant':
      return {
        ...filtersState,
        sharedToMeFromTenant: '',
      };
    case 'sharingStatusFilter':
      return {
        ...filtersState,
        sharingStatusFilter: SharingStatusFilters.Empty,
      };
    case 'teleSettingsFilter':
      return {
        ...filtersState,
        teleSettingsFilter: TeleSettingsFilters.Empty,
      };
    case 'usersInCharge':
      return {
        ...filtersState,
        usersInCharge: [],
      };
    case 'medicalConditionId':
      return {
        ...filtersState,
        medicalConditionId: null,
      };
    case PatientCategoryFilterKey:
      return {
        ...filtersState,
        patientCategory: PatientFilterCategory.All,
      };
    case 'prismaAppUsageFilter':
      return {
        ...filtersState,
        prismaAppUsageFilter: 'Empty',
      };
  }
}
