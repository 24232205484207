import { Action } from 'redux';

import { IErrorAction } from '@shared/redux/sagas/watchFunctions/watchNotificationActions';
import { EditUserPayload } from './payloadTypes/userActionsPayloads';
import { ActionWithPayload } from './typings';
import { createFormAction } from '@shared/util/createFormAction';
import { IUserDto } from '@shared/api/core-api/users-api';
import { UserFormData } from '@shared/types/userCreationTypes';

export const createUser = createFormAction('USER_CREATE')<UserFormData, IUserDto>();
export const editMyAccount = createFormAction('EDIT_MY_ACCOUNT')<EditUserPayload, void>();
export const editUser = createFormAction('USER_EDIT')<EditUserPayload, void>();

export const CREATE_USER_MODAL_OPEN = 'CREATE_USER_MODAL_OPEN';
export const CREATE_USER_MODAL_CLOSE = 'CREATE_USER_MODAL_CLOSE';

export const LOCK_USER_ACCOUNT_FORM = 'LOCK_USER_ACCOUNT_FORM';
export const UNLOCK_USER_ACCOUNT_FORM = 'UNLOCK_USER_ACCOUNT_FORM';
export const ENABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS = 'ENABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS';
export const DISABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS = 'DISABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS';

export const FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAILURE = 'FETCH_USER_BY_ID_FAILURE';
export const FETCH_USER_BY_ID_NOT_FOUND = 'FETCH_USER_BY_ID_NOT_FOUND';
export const RESET_USER_DETAILS_STATE = 'RESET_USER_DETAILS_STATE';

export const FETCH_ALL_USER_IN_CHARGE_REQUEST = 'FETCH_ALL_USER_IN_CHARGE_REQUEST';
export const FETCH_ALL_USER_IN_CHARGE_SUCCESS = 'FETCH_ALL_USER_IN_CHARGE_SUCCESS';
export const FETCH_ALL_USER_IN_CHARGE_FAILURE = 'FETCH_ALL_USER_IN_CHARGE_FAILURE';

export const FETCH_PAGE_OF_USERS_REQUEST = 'FETCH_PAGE_OF_USERS_REQUEST';
export const FETCH_PAGE_OF_USERS_SUCCESS = 'FETCH_PAGE_OF_USERS_SUCCESS';
export const FETCH_PAGE_OF_USERS_FAILURE = 'FETCH_PAGE_OF_USERS_FAILURE';

export const USER_PIC_REQUEST = 'USER_PIC_REQUEST';
export const USER_PIC_REQUEST_SUCCESS = 'USER_PIC_REQUEST_SUCCESS';
export const USER_PIC_REQUEST_FAILURE = 'USER_PIC_REQUEST_FAILURE';

export const USER_CREATE_PIC_REQUEST = 'USER_CREATE_PIC_REQUEST';
export const USER_CREATE_PIC_SUCCESS = 'USER_CREATE_PIC_SUCCESS';
export const USER_CREATE_PIC_FAILURE = 'USER_CREATE_PIC_FAILURE';

export const USER_DELETE_PIC_REQUEST = 'USER_DELETE_PIC_REQUEST';
export const USER_DELETE_PIC_SUCCESS = 'USER_DELETE_PIC_SUCCESS';
export const USER_DELETE_PIC_FAILURE = 'USER_DELETE_PIC_FAILURE';
export const USER_DELETE_PIC_REQUEST_REJECTED = 'USER_DELETE_PIC_REQUEST_REJECTED';
export const USER_DELETE_PIC_REQUEST_CONFIRMED = 'USER_DELETE_PIC_REQUEST_CONFIRMED';

export const USER_DOWNLOAD_PIC_CONFIRM = 'USER_DOWNLOAD_PIC_CONFIRM';

export const USER_DOWNLOAD_PIC_REJECTED = 'USER_DOWNLOAD_PIC_REJECTED';
export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_CONFIRM = 'USER_DELETE_CONFIRM';
export const USER_DELETE_REJECTED = 'USER_DELETE_REJECTED';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILED = 'USER_DELETE_FAILED';
export const USER_DOWNLOAD_PIC_SUCCESS = 'USER_DOWNLOAD_PIC_SUCCESS';
export const USER_DOWNLOAD_PIC_FAILED = 'USER_DOWNLOAD_PIC_FAILED';

export const UNLOCK_USER_FORM = 'UNLOCK_USER_FORM';
export const LOCK_USER_FORM = 'LOCK_USER_FORM';

export const CHANGE_USERS_SORTING = 'CHANGE_USERS_SORTING';
export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export const ACCOUNT_DETAILS_REQUEST = 'ACCOUNT_DETAILS_REQUEST';
export const ACCOUNT_DETAILS_REQUEST_SUCCESS = 'ACCOUNT_DETAILS_REQUEST_SUCCESS';
export const ACCOUNT_DETAILS_REQUEST_FAILED = 'ACCOUNT_DETAILS_REQUEST_FAILED';

export const fetchUserById = (id: string) =>
  ({
    type: FETCH_USER_BY_ID_REQUEST,
    payload: id,
  } as const);

export const fetchUserByIdSuccess = (user: IUserDto) =>
  ({
    type: FETCH_USER_BY_ID_SUCCESS,
    payload: user,
  } as const);

export const fetchUserByIdFailure = (error: unknown) =>
  ({
    type: FETCH_USER_BY_ID_FAILURE,
    error,
  } as const);

export const fetchUserByIdNotFound = (e: unknown) =>
  ({
    type: FETCH_USER_BY_ID_NOT_FOUND,
    payload: e,
  } as const);

export const resetUserDetailsState = () =>
  ({
    type: RESET_USER_DETAILS_STATE,
  } as const);

export const accountDetailsRequest = (id: string) =>
  ({
    type: ACCOUNT_DETAILS_REQUEST,
    payload: id,
  } as const);
export const accountDetailsRequestSuccess = (user: IUserDto) =>
  ({
    type: ACCOUNT_DETAILS_REQUEST_SUCCESS,
    payload: user,
  } as const);

export const accountDetailsRequestFailed = (error: unknown) =>
  ({
    type: ACCOUNT_DETAILS_REQUEST_FAILED,
    error,
  } as const);

export type FetchUserByIdRequest = ReturnType<typeof fetchUserById>;
export type FetchUserByIdSuccess = ReturnType<typeof fetchUserByIdSuccess>;
export type FetchUserByIdFailure = IErrorAction<typeof FETCH_USER_BY_ID_FAILURE>;
export type FetchUserByIdNotFound = ReturnType<typeof fetchUserByIdNotFound>;
export type ResetUserDetailsState = ReturnType<typeof resetUserDetailsState>;

export type AccountDetailsRequest = ReturnType<typeof accountDetailsRequest>;
export type AccountDetailsRequestSuccess = ReturnType<typeof accountDetailsRequestSuccess>;
export type AccountDetailsRequestFailed = IErrorAction<typeof ACCOUNT_DETAILS_REQUEST_FAILED>;

export type AccountDetailsRequestAction = Action<typeof ACCOUNT_DETAILS_REQUEST>;
export type AccountDetailsRequestSuccessAction = ActionWithPayload<IUserDto, typeof ACCOUNT_DETAILS_REQUEST_SUCCESS>;
export type UserDeleteSuccessAction = ActionWithPayload<{ userId: string }, typeof USER_DELETE_SUCCESS>;
export type CreateUserModalAction = Action<typeof CREATE_USER_MODAL_CLOSE>;
export type LockUserFormAction = Action<typeof LOCK_USER_FORM>;
export type UnlockUserFormAction = Action<typeof UNLOCK_USER_FORM>;
export type UsersActions =
  | AccountDetailsRequestAction
  | AccountDetailsRequestSuccessAction
  | FetchUserByIdRequest
  | FetchUserByIdSuccess
  | FetchUserByIdNotFound
  | FetchUserByIdFailure
  | ResetUserDetailsState
  | AccountDetailsRequest
  | AccountDetailsRequestSuccess
  | AccountDetailsRequestFailed
  | CreateUserModalAction
  | LockUserFormAction
  | UnlockUserFormAction
  | UserDeleteSuccessAction;
